import axios from 'axios'
import store from '@/store/store'
import {router} from '@/router/router.js'
import {indexedStore} from '@/indexedDB/indexedStore'

const axiosInstance = axios.create({
  baseURL: import.meta.env.VUE_APP_API_URL,
  // withCredentials: true,
  headers: {
    'Accept': '*/*',
    'Content-Type': 'application/json',
  },
})

axiosInstance.interceptors.request.use(async (config) => {
  const value = store.state.user.token
  if (value) {
    config.headers['Authorization'] = `Token ${value}`
  }
  return config
})

axiosInstance.interceptors.response.use(undefined, async (error) => {

  if (!window.navigator.onLine && error.code === "ERR_NETWORK") {
    await store.dispatch('app/setOnlineState', false)
    return Promise.reject(error)
  }

  if (error?.response?.status === 404 && !import.meta.env.VUE_APP_IS_DEVELOPMENT) {
    router.push({name: '404'})
    return Promise.reject(error)
  }

  if (error?.response?.status === 401) {
    if (error.response?.data?.detail && error.response.data.detail === 'Invalid token.') {
      await store.dispatch('user/logout')
    }
    return Promise.reject(error)
  }

  return Promise.reject(error)
})

export const appAxios = {

  subscription: {
    init(subscription_price_id, payment_type_id, payment_type_option_id) {
      return axiosInstance({
        url: `/api/subscription/my/`,
        method: 'POST',
        data: {
          subscription_price_id,
          payment_type_id,
          payment_type_option_id,
        }
      })
    }
  },

  chat: {
    async fetchAll() {
      return await indexedStore.slices.base.indexingResponse('chat-fetchAll', axiosInstance({
        url: `/api/chat/`,
        method: 'GET',
      }))
    },

    async start(user_to_id) {
      await indexedStore.slices.base.invalidate('chat-fetchAll')
      return await axiosInstance({
        url: `/api/chat/`,
        method: 'POST',
        data: {
          user_to_id
        }
      })
    },

    async markChecked(chatId, messageIds) {
      await indexedStore.slices.base.invalidate('chat-fetchAll')
      return await axiosInstance({
        url: `/api/chat/${chatId}/mark_checked/`,
        method: 'POST',
        data: {
          message_ids: messageIds,
        }
      })
    }
  },

  events: {
    async fetchAll() {
      return await indexedStore.slices.base.indexingResponse('events-fetchAll', axiosInstance({
        url: `/api/event/`,
        method: 'GET',
      }))
    },
    fetchOne(id) {
      return axiosInstance({
        url: `/api/event/${id}/`,
        method: 'GET',
      })
    },
  },

  auth: {
    sendAuthCode(phone) {
      return axiosInstance({
        url: `/api/auth/phone/send-code/`,
        method: 'POST',
        data: {
          phone,
        }
      })
    },

    checkAuthCode(phone, code) {
      return axiosInstance({
        url: `/api/auth/phone/check-code/`,
        method: 'POST',
        data: {
          'phone': phone,
          'code': code
        }
      })
    },

    register(data) {
      return axiosInstance({
        url: `/api/register/`,
        method: 'POST',
        data: {
          'first_name': data.first_name,
          'last_name': data.last_name,
          'phone': data.phone
        }
      })
    },

    sendRegisterCode(phone) {
      return axiosInstance({
        url: `/api/register/phone/send-code/`,
        method: 'POST',
        data: {
          phone,
        }
      })
    },

    checkRegisterCode(phone, code) {
      return axiosInstance({
        url: `/api/register/phone/check-code/`,
        method: 'POST',
        data: {
          'phone': phone,
          'code': code
        }
      })
    },
  },

  specializations: {
    add(specialization_id) {
      return axiosInstance({
        url: `/api/specialization/my/`,
        method: 'POST',
        data: {
          specialization_id,
        },
      })
    },
    remove(id) {
      return axiosInstance({
        url: `/api/specialization/my/${id}/`,
        method: 'DELETE',
      })
    },
  },

  educations: {
    fetchAll() {
      return axiosInstance({
        url: `/api/education/my/`,
        method: 'GET',
      })
    },

    async create(values) {
      const formData = new FormData()
      const {images, ...data} = values

      if (images && Array.isArray(images)) {
        const toCreate = images.filter(i => i.changed && !i.isDeleted)
        toCreate.map(i => formData.append('images', i.file))
      }

      Object.keys(data).forEach(key => {
        formData.append(key, data[key])
      })

      return await axiosInstance({
        url: `/api/education/my/`,
        method: 'POST',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'},
      })
    },

    async update(values) {
      const educationId = store.getters['user/education'].id
      const formData = new FormData()

      const {images, ...data} = values

      if (images && Array.isArray(images)) {
        const toRemove = images.filter(i => i.changed && i.isDeleted)
        const toCreate = images.filter(i => i.changed && !i.isDeleted)

        const deletePromises = toRemove.filter(i => i.id).map(i => {
          return appAxios.educations.deleteImage(data.education_type, i.id)
        })

        toCreate.map(i => formData.append('images', i.file))

        await Promise.all(deletePromises)
      }

      Object.keys(data).forEach(key => {
        if (data[key]) {
          formData.append(key, data[key])
        }
      })

      formData.append('instance_id', data.id)

      return await axiosInstance({
        url: `/api/education/my/${educationId}/`,
        method: 'PUT',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'},
      })
    },

    async deleteImage(type, id) {
      return axiosInstance({
        url: `/api/${type}-image/${id}/`,
        method: 'DELETE',
      })
    },

    // router.register(r"api/community-image/", common.views.CommunityImageViewSet)
    // router.register(r"api/course-image/", common.views.CourseImageViewSet)
    // router.register(r"api/institution-image/", common.views.InstitutionImageViewSet)

    remove(education, education_type) {
      const educationId = store.getters['user/education'].id
      return axiosInstance({
        url: `/api/education/my/${educationId}/delete/`,
        method: 'POST',
        data: {
          instance_id: education.id,
          education_type: education_type,
        }
      })
    },
  },

  achievements: {
    fetchAll() {
      return axiosInstance({
        url: `/api/achievements/my/`,
        method: 'GET',
      })
    },

    create(text) {
      return axiosInstance({
        url: `/api/achievements/my/`,
        method: 'POST',
        data: {
          title: text,
        },
      })
    },

    update(achievement) {
      return axiosInstance({
        url: `/api/achievements/my/${achievement.id}/`,
        method: 'PUT',
        data: {
          ...achievement,
        },
      })
    },

    remove(achievement) {
      return axiosInstance({
        url: `/api/achievements/my/${achievement.id}/`,
        method: 'DELETE',
      })
    },
  },

  niche: {
    fetchAll() {
      return axiosInstance({
        url: `/api/niche/my/`,
        method: 'GET',
      })
    },

    create(title) {
      return axiosInstance({
        url: `/api/niche/my/`,
        method: 'POST',
        data: {
          title,
        },
      })
    },

    remove(user_niche_id) {
      return axiosInstance({
        url: `/api/niche/my/${user_niche_id}/`,
        method: 'DELETE',
      })
    },
  },

  skills: {
    fetchAll() {
      return axiosInstance({
        url: `/api/skill/my/`,
        method: 'GET',
      })
    },

    create(title) {
      return axiosInstance({
        url: `/api/skill/my/`,
        method: 'POST',
        data: {
          title: title,
        },
      })
    },

    remove(user_skill_id) {
      return axiosInstance({
        url: `/api/skill/my/${user_skill_id}/`,
        method: 'DELETE',
      })
    },
  },

  profession: {
    fetchAll() {
      return axiosInstance({
        url: `/api/profession/my/`,
        method: 'GET',
      })
    },

    create(title) {
      return axiosInstance({
        url: `/api/profession/my/`,
        method: 'POST',
        data: {
          title,
        },
      })
    },

    remove(user_profession_id) {
      return axiosInstance({
        url: `/api/profession/my/${user_profession_id}/`,
        method: 'DELETE',
      })
    },
  },

  user: {
    fetchAll() {
      return axiosInstance({
        url: `/api/user/`,
        method: 'GET',
      })
    },

    search(data) {
      return axiosInstance({
        url: `api/user/search/`,
        method: 'POST',
        data: {
          ...data,
        }
        // "search_query": search_query ? search_query : undefined,
        // "profession_ids": [],
        // "nicheIds": [],
        // "skillIds": [],
        // "ordering": "relative"
        //'rating', 'relative'
      })
    },

    searchPaginated(data, limit, offset) {
      return axiosInstance({
        url: `api/user/paginated_search/?limit=${limit}&offset=${offset}`,
        method: 'POST',
        data: {
          ...data,
          // "search_query": search_query ? search_query : undefined,
          // "professionIds": [],
          // "nicheIds": [],
          // "skillIds": [],
          // "ordering": "relative"
          //'rating', 'relative'
        }
      })
    },

    updateMyProfile(data) {
      const profile = store.state.user.profile
      const formData = new FormData()

      Object.keys(data).forEach(key => {
        formData.append(key, data[key])
      })

      return axiosInstance({
        url: `/api/user/my/${profile.id}/`,
        method: 'PUT',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'},
      })
    },

    fetchMyProfile() {
      return axiosInstance({
        url: '/api/user/my/',
        method: 'GET',
      })
    },

    async fetchDirectories() {
      return await indexedStore.slices.base.indexingResponse('user-fetchDirectories', axiosInstance({
        url: `/api/directories/`,
        method: 'GET',
      }))
    },

    fetchProfile(userId) {
      return axiosInstance({
        url: `/api/user/${userId}/`,
        method: 'GET',
      })
    },
  },

  guest: {
    async fetchDirectories() {
      return await indexedStore.slices.base.indexingResponse('guest-fetchDirectories', axiosInstance({
        url: `/api/directories_unauth/`,
        method: 'GET',
      }))
    },

    getUser(id) {
      return axiosInstance({
        url: `/api/user/${id}/`,
        method: 'GET',
      })
    },

    getEvent(id) {
      return axiosInstance({
        url: `/api/event/${id}/`,
        method: 'GET',
      })
    },

    getCourse(id) {
      return axiosInstance({
        url: `/api/lms/courses/${id}/`,
        method: 'GET',
      })
    },

    getProduct(id) {
      return axiosInstance({
        url: `/api/product/${id}/`,
        method: 'GET',
      })
    },
  },

  myModules: {
    start(ModuleId) {
      return axiosInstance({
        url: `/api/lms/modules/my/`,
        method: 'POST',
        data: {
          module_id: ModuleId,
        }
      })
    },

    finish(studentModuleId) {
      return axiosInstance({
        url: `/api/lms/modules/my/${studentModuleId}/finish/`,
        method: 'POST',
        data: {
          id: studentModuleId
        }
      })
    },
  },

  myLessons: {

    start(LessonId) {
      return axiosInstance({
        url: `/api/lms/lessons/my/`,
        method: 'POST',
        data: {
          lesson_id: LessonId,
        }
      })
    },

    finish(studentLessonId) {
      return axiosInstance({
        url: `/api/lms/lessons/my/${studentLessonId}/finish/`,
        method: 'POST',
        data: {
          id: studentLessonId
        }
      })
    }
  },

  myTest: {
    start(lesson_test_id) {
      return axiosInstance({
        url: `/api/lms/lesson/tests/my/`,
        method: 'POST',
        data: {
          lesson_test_id: lesson_test_id,
        }
      })
    },

    update(student_lesson_test_id, test_answers_ids) {
      return axiosInstance({
        url: `/api/lms/lesson/tests/my/${student_lesson_test_id}/`,
        method: 'PUT',
        data: {
          test_answers_ids: test_answers_ids,
        }
      })
    },

    finish(student_lesson_test_id) {
      return axiosInstance({
        url: `/api/lms/lesson/tests/my/${student_lesson_test_id}/finish/`,
        method: 'POST',
        data: {
          lesson_test_id: student_lesson_test_id,
        }
      })
    }
  },

  myCourses: {
    finish(studentCourseId) {
      return axiosInstance({
        url: `/api/lms/courses/my/${studentCourseId}/finish/`,
        method: 'POST',
        data: {
          id: studentCourseId
        }
      })
    },

    async fetchOne(userCourseId) {
      // return await indexedStore.slices.myCourses.indexingResponse(userCourseId, axiosInstance({
      //   url: `/api/lms/courses/my/${userCourseId}/`,
      //   method: 'GET',
      // }))
      return await axiosInstance({
        url: `/api/lms/courses/my/${userCourseId}/`,
        method: 'GET',
      })
    },

    async fetchAll(limit = 50, offset = 0) {
      // return await indexedStore.slices.base.indexingResponse('myCourses-fetchAll', axiosInstance({
      //   url: `/api/lms/courses/my/?limit=${limit}&offset=${offset}`,
      //   method: 'GET',
      // }))
      return await axiosInstance({
        url: `/api/lms/courses/my/?limit=${limit}&offset=${offset}`,
        method: 'GET',
      })
    },

    review(course_id, review_items) {
      return axiosInstance({
        url: `/api/lms/course-review/my/`,
        method: 'POST',
        data: {
          course_id,
          review_items,
          // review_items: [
          // {
          //   "text_grade": "string",
          //   "integer_grade": 0,
          //   "attache": "string",
          //   "review_item_id": 0,
          // }
          // ]
        }
      })
    },
  },

  course: {
    async fetchAll(limit = 50, offset = 0) {
      return await indexedStore.slices.base.indexingResponse('course-fetchAll', axiosInstance({
        url: `/api/lms/courses/?limit=${limit}&offset=${offset}`,
        method: 'GET',
      }))
    },
    fetchOne(courseId) {
      return axiosInstance({
        url: `/api/lms/courses/${courseId}/`,
        method: 'GET',
      })
    },
    async enroll(course_id) {
      await indexedStore.slices.base.invalidate('course-fetchAll')
      return axiosInstance({
        url: `/api/lms/courses/${course_id}/enroll/`,
        method: 'POST',
        data: {
          'email': 'mail@example.com',
        }
      })
    },
  },

  products: {
    fetchAll() {
      return axiosInstance({
        url: `/api/product/`,
        method: 'GET',
      })
    },

    get(productId) {
      return axiosInstance({
        url: `/api/product/my/${productId}/`,
        method: 'GET',
      })
    },

    fetchMyAll() {
      return axiosInstance({
        url: `/api/product/my/`,
        method: 'GET',
      })
    },

    create(values) {
      const formData = new FormData()

      Object.keys(values).forEach(key => {
        formData.append(key, values[key])
      })

      return axiosInstance({
        url: `/api/product/`,
        method: 'POST',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'},
      })
    },

    update(product) {
      const formData = new FormData()

      Object.keys(product).forEach(key => {
        formData.append(key, product[key])
      })

      return axiosInstance({
        url: `/api/product/my/${product.id}/`,
        method: 'PUT',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'},
      })
    },

    remove(product) {
      return axiosInstance({
        url: `/api/product/my/${product.id}/`,
        method: 'DELETE',
      })
    },
  },

  onboarding: {
    start(step) {
      return axiosInstance({
        url: '/api/onboarding/my/',
        method: 'POST',
        data: {
          step,
        }
      })
    },
    finish(id) {
      return axiosInstance({
        url: `/api/onboarding/my/${id}/`,
        method: 'PUT',
      })
    },
  },

  networking: {
    fetch(limit) {
      return axiosInstance({
        url: `/api/user/networking_profiles_limited/?limit=${limit}`,
        method: 'GET',
      })
    },

    async checkProfile(id) {
      await indexedStore.slices.base.invalidate('networking-fetchRecommendations')
      return axiosInstance({
        url: `/api/user-checked-profile/my/`,
        method: 'POST',
        data: {
          checked_profile_id: id,
        }
      })
    },

    async fetchRecommendations() {
      return await indexedStore.slices.base.indexingResponse('networking-fetchRecommendations', axiosInstance({
        url: `/api/user/networking_profiles_limited/?limit=5`,
        method: 'GET',
      }))
    },
  },

  myContacts: {
    async fetchAll() {
      return await indexedStore.slices.base.indexingResponse('myContacts-fetchAll', axiosInstance({
        url: '/api/user-contact/my/',
        method: 'GET',
      }))
    },

    searchPaginated(data, limit, offset) {
      return axiosInstance({
        url: `api/user-contact/my/paginated_search/?limit=${limit}&offset=${offset}`,
        method: 'POST',
        data: {
          ...data,
        }
      })
    },

    async addContact(userId, rating) {
      await indexedStore.slices.base.invalidate('myContacts-fetchAll')
      return await axiosInstance({
        url: '/api/user-contact/my/',
        method: 'POST',
        data: {
          'contact_user_id': userId,
          'rating': rating,
        }
      })
    },

    async updateContact(userContactId, rating) {
      await indexedStore.slices.base.invalidate('myContacts-fetchAll')
      return await axiosInstance({
        url: `/api/user-contact/my/${userContactId}/`,
        method: 'PUT',
        data: {
          'rating': rating,
        }
      })
    },

    async removeContact(userContactId) {
      await indexedStore.slices.base.invalidate('myContacts-fetchAll')
      return await axiosInstance({
        url: `/api/user-contact/my/${userContactId}/`,
        method: 'DELETE',
      })
    },
  },

  roulette: {
    play() {
      return axiosInstance({
        url: `/api/lms/courses/my/play_roulette/`,
        method: 'POST',
      })
    },
    async check() {
      return await indexedStore.slices.base.indexingResponse('roulette-check', axiosInstance({
        url: `/api/lms/courses/my/check_roulette_play/`,
        method: 'GET',
      }))
    },
  },
}
