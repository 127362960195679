<template>
  <auth-default>
    <div class="login_container main_container" v-if="!state.send_msg">
      <div class="auth_head">
        <Logo/>
      </div>
      <h1 class="title">Вход в профиль</h1>
      <p class="description">Введи номер телефона</p>
      <div class="form">

        <form @submit.prevent="onSubmit">
          <TextInput
              name="phone"
              type="phone"
              label="Номер телефона"
              placeholder=""
              :mask="state.phoneMask"
              pattern="[0-9]*"
              inputmode="tel"
              dataMaskaTokens="#:[0-9]|T:[0-9+]"
              @paste="e => {
                 let paste = (e.clipboardData || window.clipboardData).getData('text');
                 let cleared = paste.trim()
                 if(paste.at(0) === '8') {
                   cleared = paste.replace('8', '+7')
                 }
                 setFieldValue('phone', cleared)
              }"
          />
          <AppButton
              full-width
              :loading="isSubmitting"
              :disabled="isSubmitting"
              @click="onSubmit"
              type="submit"
          >
            Войти
          </AppButton>

<!--          <Card style="margin-top: 12px">-->
<!--            <typography variant="v14-400">-->
<!--              Если у тебя иностранный номер телефона, введи его в поле выше и переходи к следующему шагу. Что делать-->
<!--              дальше, расскажем там-->
<!--&lt;!&ndash;              Если у тебя иностранный номер телефона, обратись в нашу&ndash;&gt;-->
<!--&lt;!&ndash;              <AppLink&ndash;&gt;-->
<!--&lt;!&ndash;                  color="#FF303A"&ndash;&gt;-->
<!--&lt;!&ndash;                  @click="clickOpenURL(store.state.app.info.support_chat_link.link)"&ndash;&gt;-->
<!--&lt;!&ndash;                  style="display: inline"&ndash;&gt;-->
<!--&lt;!&ndash;              >&ndash;&gt;-->
<!--&lt;!&ndash;                службу поддержки,&ndash;&gt;-->
<!--&lt;!&ndash;              </AppLink>&ndash;&gt;-->
<!--&lt;!&ndash;              что делать расскажем там&ndash;&gt;-->
<!--            </typography>-->
<!--          </Card>-->

        </form>
      </div>
      <div class="login_bottom">
        <p>Ещё нет аккаунта?</p>
        <router-link class="register_link" :to="{ name: 'Register' }">Зарегистрироваться</router-link>
      </div>
    </div>

    <!--    v-if="state.send_msg"-->
    <!--    <OtpCode-->
    <!--        v-if="state.send_msg"-->
    <!--        :phone="state.phone"-->
    <!--        :error="state.error"-->
    <!--        v-model:show="state.send_msg"-->
    <!--        v-model:msg_code="state.msg_code"-->
    <!--    />-->

    <CheckMsg
        v-if="state.send_msg"
        :phone="state.phone"
        :error="!!state.error"
        :errorMsg="state.error"
        v-model:show="state.send_msg"
        v-model:msg_code="state.msg_code"
    />
  </auth-default>
</template>

<script setup>
import AuthDefault from '@/views/auth/AuthDefault.vue'
import {Form, useForm} from 'vee-validate'
import * as Yup from 'yup'
import TextInput from '@/components/form/FormTextInput.vue'
import {nextTick, onMounted, onUnmounted, reactive, watch} from 'vue'
import {useRouter} from 'vue-router'
import {useStore} from 'vuex'
import AppButton from '@/components/UI/AppButton.vue'
import {appAxios} from '@/axios.js'
import CheckMsg from '@/views/auth/CheckMsg.vue'
import Logo from "@/components/UI/Logo.vue"
import Card from "@/components/UI/Card.vue"
import AppLink from "@/components/AppLink.vue"
import Typography from "@/components/UI/Typography.vue"
import * as libPhoneNumber from 'libphonenumber-js'

const router = useRouter()
const store = useStore()

const state = reactive({
  send_msg: false,
  error: '',
  phone: '',
  msg_code: '',
  ac: null,
  phoneMask: '+######################'
})

const {handleSubmit, setErrors, values, isSubmitting, isFieldValid, setFieldValue, isValidating, setValues} = useForm({
  validationSchema: Yup.object({
    phone: Yup.string().label('Номер телефона')
        .required()
        .test('phone', 'Некорректный номер телефона', value => {
          try {
            const phone = libPhoneNumber.parsePhoneNumber(value)
            return phone.isValid()
          } catch (e) {
            return false
          }
        }),
  }),
})

watch(values, values => {
  try {
    const value = String(values.phone).replaceAll(' ', '')
    state.phoneNumberObj = libPhoneNumber.parsePhoneNumber(value)
    state.formatIn = state.phoneNumberObj.formatInternational()

    const newMask = state.formatIn.toString().replace(/\d/g, '#') + ''
    if (newMask !== state.phoneMask) {
      state.phoneMask = newMask
    }

    if (state.phoneNumberObj.number && state.phoneNumberObj.number !== value) {
      setValues({
        phone: state.phoneNumberObj.number,
      })
    }
  } catch (e) {
    state.phoneMask = '+######################'
    state.phoneNumberObj = null
  }
})

watch(() => [values.phone, isValidating], ([phone, isValidating]) => {
  if (phone && !isValidating.value) {
    try {
      const phone = libPhoneNumber.parsePhoneNumber(value)
      if(phone.isValid()) {
        sessionStorage.setItem('loginTel', value)
      }
    } catch (e) {

    }
  }
})

onMounted(() => {
  const phone = sessionStorage.getItem('loginTel')
  if (!phone) {
    return
  }
  setValues({
    phone
  })
  requestAnimationFrame(() => {
    setErrors({
      phone: null,
    })
    requestAnimationFrame(() => {
      setErrors({
        phone: null,
      })
      requestAnimationFrame(() => {
        setErrors({
          phone: null,
        })
      })
    })
  })
})

onUnmounted(() => {
  if (state.ac) {
    state.ac.abort('abort previous otp code request')
  }
})

function clickOpenURL(url) {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href: url,
  }).click();
}

const onSubmit = handleSubmit(async values => {
  const phone = values.phone.replace(/[+ ()-]/g, '')
  try {
    let res = await appAxios.auth.sendAuthCode(phone)
    state.send_msg = true
    state.phone = values.phone

    if (state.ac) {
      state.ac.abort('abort previous otp code request')
    }
    state.ac = new AbortController()

    navigator.credentials
        .get({
          otp: {transport: ['sms']},
          signal: state.ac.signal,
        })
        .then((otp) => {
          state.msg_code = otp.code
        })
        .catch((err) => {
          // console.error(err)
        })
  } catch (err) {
    console.log(err)
    if (err?.response?.data) {
      setErrors({
        ...err?.response?.data,
        phone: err?.response?.data?.phone ?? err.response.data.detail,
      })
      return
    }
    throw err
  }
})

async function checkCode() {
  const res = await store.dispatch('user/login', {
    phone: state.phone,
    code: state.msg_code,
  })
  if (res && res.error) {
    const err = res.error
    if (err?.response?.data?.detail) {
      state.error = err.response.data.detail
    }
  }
}

watch(
    () => state.msg_code,
    (msg_code) => {
      if (msg_code.length === 4) {
        checkCode()
      } else {
        state.error = ''
      }
    }
)

</script>

<style lang="scss" scoped>
.login_container {
  .title {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 8px;
  }

  .description {
    color: #A6A5A5;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 24px;
  }

  .form {
    margin-bottom: 32px;

    .form_control {
      margin-bottom: 24px;

      label {
        color: rgb(166, 165, 165);
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0;
        text-align: left;
        margin-bottom: 4px;
      }

      input {
        width: 100%;
        display: block;
        padding: 12px;
        border: 1px solid rgb(73, 73, 73);
        border-radius: 12px;
        color: rgb(255, 255, 255);
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
      }
    }
  }

  .login_bottom {
    p {
      color: rgb(166, 165, 165);
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0;
      text-align: center;
      margin-bottom: 4px;
    }

    .register_link {
      width: 100%;
      color: rgb(255, 255, 255);
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0;
      text-align: center;
    }
  }
}
</style>
