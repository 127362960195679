<template>
  <head-new
      :page_title="state.isCreate ? 'Добавить' : 'Редактировать'"
      :back-action="() => router.push({name: $route.meta.returnName ?? 'EditProducts'})"
  />

  <div class="main_container form">
    <template v-if="state.product && state.product.status">
      <Card style="margin-bottom: 32px">
        <Typography
            variant="subtitle2"
            :color="productsStatus[state.product.status].color"
            style="margin-bottom: 4px"
        >
          {{ productsStatus[state.product.status].title }}
        </Typography>

        <Typography variant="body1">
          После проверки продукт будет опубликован на ярмарке
        </Typography>
      </Card>
    </template>

    <ImageCover
        label="Обложка продукта"
        name="image"
        :imagePreview="state.imagePreview"
        :aspect-ratio="328 / 144"
    />

    <FormTextAreaGrowable
        label="Заголовок"
        name="title"
        :rows="2"
    />

    <FormTextAreaGrowable
        label="Описание"
        name="description"
        :rows="4"
    />

    <FormTextInput
        label="Цена"
        name="price"
        value="Бесплатно"
        text-info="Все продукты пока размещаются бесплатно"
        disabled
    />

    <Card class="publishedCard">
      Показывать на ярмарке
      <FormSwitch
          label=""
          name="is_published"
      />
    </Card>

    <div class="buttons">
      <appButton
          full-width
          class="submitButton"
          :loading="isSubmitting"
          :disabled="isSubmitting"
          @click="onSubmit"
      >
        Сохранить
      </appButton>

      <appButton
          full-width
          class="submitButton"
          :disabled="isSubmitting"
          variant="secondary"
          @click="onDelete"
      >
        {{ state.isCreate ? 'Отменить' : 'Удалить' }}
      </appButton>
    </div>

    <BottomDrawer
        :is-shown="state.isDeleteId"
        title="Удаление данных"
        @close="state.isDeleteId = null"
    >
      <Typography variant="body1" color="#D3D2D2">
        Ты точно хочешь удалить этот продукт?
      </Typography>

      <div class="modalButtons">

        <appButton
            variant="secondary"
            @click="state.isDeleteId = null"
        >
          Оставить
        </appButton>

        <appButton
            variant="redSecondary"
            @click="deleteProduct(state.isDeleteId)"
        >
          Удалить
        </appButton>

      </div>

    </BottomDrawer>
  </div>
</template>

<script setup>
import HeadNew from "@/components/Head.vue"
import {useForm} from 'vee-validate'
import * as Yup from 'yup'
import store from '@/store/store'
import {onBeforeMount, onMounted, reactive, watch} from 'vue'
import {router} from '@/router/router'
import AppButton from '@/components/UI/AppButton.vue'
import FormTextArea from '@/components/form/FormTextArea.vue'
import {useRoute} from 'vue-router'
import {appAxios} from '@/axios'
import Typography from '@/components/UI/Typography.vue'
import BottomDrawer from '@/components/BottomDrawer.vue'
import FormTextInput from '@/components/form/FormTextInput.vue'
import FormSwitch from '@/components/form/FormSwitch.vue'
import Card from '@/components/UI/Card.vue'
import ImageCover from '@/components/form/FormImageCover.vue'
import {productsStatus} from "../../../configs/productsStatus.js";
import FormTextAreaGrowable from "@/components/form/FormTextAreaGrowable.vue";

const props = defineProps({
  productId: {
    type: String,
  }
})

const route = useRoute()

const state = reactive({
  isCreate: route.meta.isCreate,
  isLoading: true,
  imagePreview: null,
  product: null,
  isDeleteId: null,
})

const {handleSubmit, values, isSubmitting, setFieldValue, setValues, setErrors} = useForm({
  validationSchema: Yup.object({
    title: Yup.string().label('Заголовок').required().max(80),
    description: Yup.string().label('Описание').required().max(300),
  }),
});

onBeforeMount(async () => {
  try {
    if (props.productId) {
      const res = await appAxios.products.get(props.productId)
      state.product = res.data
      state.imagePreview = state.product.image ? state.product.image : null
      setValues({
        title: state.product.title,
        description: state.product.description,
        is_published: state.product.is_published,
        image: state.product.image ? state.product.image : null,
      })
    }
  } catch (e) {
    // todo: error?
  } finally {
    state.isLoading = false
  }
})

onMounted(() => {
  if (state.isCreate) {
    setValues({
      is_published: false,
    })
    requestAnimationFrame(() => {
      setErrors({
        phone: null,
      })
      requestAnimationFrame(() => {
        setErrors({
          phone: null,
        })
        requestAnimationFrame(() => {
          setErrors({
            phone: null,
          })
        })
      })
    })
  }
})

const onDelete = async function () {
  if (state.isCreate) {
    return router.push({
      name: route.meta.isCheckList ? 'CheckListEditProducts' : 'EditProducts',
    })
  } else {
    // const found = store.state.user.profile.products.find(item => item.id.toString() === props.productId.toString())
    state.isDeleteId = Number(props.productId)
  }
}

const onSubmit = handleSubmit(async values => {
  if (state.isCreate) {
    const payload = {
      ...values,
    }
    if (values.image instanceof window.File) {
      // fine
    } else {
      delete payload.image
    }
    const res = await appAxios.products.create(payload)
  } else {
    const payload = {
      ...state.product,
      ...values,
    }
    if (values.image instanceof window.File) {
      // fine
    } else {
      delete payload.image
    }
    const res = await appAxios.products.update(payload)
  }
  const resResult = await appAxios.products.fetchMyAll()

  await store.commit('user/setProfile', {
    products: resResult.data,
  })

  await router.push({name: route.meta.returnName ?? 'EditProducts'})
})

async function deleteProduct(id) {
  const res = await appAxios.products.remove({id})

  const resResult = await appAxios.products.fetchMyAll()

  state.productsList = resResult.data

  // await store.commit('user/setProfile', {
  //   products: resResult.data,
  // })

  state.isDeleteId = null
  await router.push({name: route.meta.isCheckList ? 'CheckListEditProducts' : 'EditProducts'})
}

</script>

<style lang="scss" scoped>

.form {
  margin-top: 24px;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 32px;
}

.modalButtons {
  display: flex;
  gap: 8px;
  margin-top: 32px;
  margin-bottom: 16px;

  & > * {
    flex-grow: 1;
  }
}

.publishedCard {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

</style>
