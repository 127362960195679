<template>
  <div class="main_container">
    <div class="title">
      Чаты
    </div>

    <div class="chatList">
      <ChatLine
          v-if="!state.isLoading"
          v-for="chat in state.chats"
          :key="chat.id"
          :chat="chat"
      />
      <ChatLine
          v-if="state.isLoading"
          :is-loading="true"
          v-for="_ in 8"
      />
    </div>
  </div>
</template>

<script setup>

import {onBeforeMount, reactive, watch} from 'vue'
import {appAxios} from '@/axios'
import ChatLine from '@/views/chat/ChatLine.vue'
import store from "@/store/store.js";
import {indexedStore} from "@/indexedDB/indexedStore.js";

const state = reactive({
  isLoading: true,
  chats: [],
})

async function checkProfilesMessages(uncheckedMessages) {
  let needUpdate = false
  state.chats.forEach(chat => {
    chat.unchecked_messages.forEach(m => {
      m.checked_at = m.created_at
    })
  })
  uncheckedMessages.forEach(item => {
    const id = item.chat_id
    const found = state.chats.find(chat => {
      return String(chat.id) === String(id)
    })
    if (found) {
      if (item.count === 0) {
        found.unchecked_messages.forEach(m => {
          m.checked_at = m.created_at
        })
      } else {
        found.last_message = item.last_message
        found.unchecked_messages = [
          item.last_message,
          ...Array(item.count - 1).fill(0).map(() => ({
            sent_by: -1,
          })),
        ]
      }
    } else {
      if (item.count > 0) {
        needUpdate = true
      }
    }
  })
  if (needUpdate) {
    await indexedStore.slices.base.invalidate('chat-fetchAll')
    const res = await appAxios.chat.fetchAll()
    const chats = res.data
    state.chats = [
      ...chats
          .filter(chat => store.state.user.directories.support_chat_user.id === chat.user_from.id),
      ...chats
          .filter(chat => store.state.user.directories.support_chat_user.id !== chat.user_from.id && chat.last_message)
          .sort((a, b) => new Date(b.last_message?.created_at) - new Date(a.last_message?.created_at))
    ]
  }
}

watch(() => store.state.user.profile.unchecked_messages, async uncheckedMessages => {
  await checkProfilesMessages(uncheckedMessages)
})

onBeforeMount(async () => {
  try {
    const res = await appAxios.chat.fetchAll()
    const chats = res.data
    state.chats = [
      ...chats
          .filter(chat => store.state.user.directories.support_chat_user.id === chat.user_from.id),
      ...chats
          .filter(chat => store.state.user.directories.support_chat_user.id !== chat.user_from.id && chat.last_message)
          .sort((a, b) => new Date(b.last_message?.created_at) - new Date(a.last_message?.created_at))
    ]
    await checkProfilesMessages(store.state.user.profile.unchecked_messages)
  } catch (e) {
    throw e
  } finally {
    state.isLoading = false
  }
})

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.title {
  margin-top: $topOffset;
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 24px;
}

.chatList {
  display: flex;
  flex-direction: column;

}

</style>
